import { Injectable, OnDestroy } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoadingBarService implements OnDestroy {
  inProgress$: Subject<boolean>;

  private pendingRequests = 0;

  constructor(private router: Router) {
    this.pendingRequests = 0;
    this.inProgress$ = new Subject<boolean>();
    this.router.events.subscribe((event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.start();
          break;
        }

        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.complete();
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  start() {
    // console.log('--start');
    this.pendingRequests = this.pendingRequests + 1;

    if (this.pendingRequests > 0) {
      this.inProgress$.next(true);
    }
  }

  complete() {
    // console.log('--complete');
    if (this.pendingRequests === 0) {
      return;
    }

    if (this.pendingRequests > 0) {
      this.pendingRequests = this.pendingRequests - 1;
    }

    if (this.pendingRequests === 0) {
      this.inProgress$.next(false);
    }
  }

  ngOnDestroy() {
    this.inProgress$.complete();
  }
}

// import { AccessType, Entity, NotificationStatus } from '@vertice/enums';
import {
  AuthService,
  // OrganizationsQuery,
  // OrganizationsService,
  // OrganizationsStore,
  // UsersQuery,
  // UsersService,
  // goTo,
} from '@vertice/state';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Observable, map } from 'rxjs';
import { User } from '@vertice/data';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 've-side-navbar',
  templateUrl: './side-navbar.component.html',
  styles: [],
})
export class SideNavbarComponent {
  // Entity = Entity;
  // AccessType = AccessType;

  @Input() sideNavBarItems: any;
  // organizations$: Observable<Organization[]>;

  newNotificationCount = 0;
  isExpanded = false;
  isOpen = false;
  user!: User & { url: any };
  @Output() toggleMenu = new EventEmitter();
  openDialog: any;

  isNotificationOpen = false;


  constructor(
    private auth: AuthService,
    private ref: ChangeDetectorRef,
    private translateService: TranslateService
  ) {
    translateService.addLangs(['en', 'pt']);
    translateService.setDefaultLang('en');
    const browserLang = translateService.getBrowserLang();
    translateService.use(
      browserLang || 'pt'
    );
  }

  onClickNavItem(index: number): void {
    this.sideNavBarItems.map((item: any) => (item.isActive = false));
    this.sideNavBarItems[index].isActive = true;
  }

  ontoggleMenu() {
    this.isExpanded = !this.isExpanded;
    this.toggleMenu.emit(this.isExpanded);
  }

  mouseEnter(): void {
    if (!this.isExpanded) {
      this.toggleMenu.emit(true);
    }
  }

  mouseOut(): void {
    if (!this.isExpanded) {
      this.toggleMenu.emit(false);
    }
  }

  logout() {
    this.auth.logout();
  }

  // notificationIconClick() {
  //   this.isNotificationOpen = !this.isNotificationOpen;
  //   this.usersService
  //     .update(this.usersQuery.getActiveId() + '', {
  //       lastNotificationPopupOpen: new Date().toISOString(),
  //     })
  //     .subscribe();
  // }
}

// @UntilDestroy()
// @Component({
//   selector: 've-side-navbar',
//   templateUrl: './side-navbar.component.html',
//   styles: [],
// })
// export class SideNavbarComponent {
//   Entity = Entity;
//   AccessType = AccessType;

//   @Input() sideNavBarItems: any;
//   // organizations$: Observable<Organization[]>;

//   newNotificationCount = 0;
//   isExpanded = false;
//   isOpen = false;
//   user!: User & { url: any };
//   @Output() toggleMenu = new EventEmitter();
//   openDialog: any;

//   isNotificationOpen = false;

//   activeOrganizationId$ = this.organizationsQuery
//     .selectActiveId()
//     .pipe(map((e) => e + ''));

//   // organization$: Observable<Organization>;

//   constructor(
//     // private usersQuery: UsersQuery,
//     // private usersService: UsersService,
//     // private organizationsQuery: OrganizationsQuery,
//     private auth: AuthService,
//     private ref: ChangeDetectorRef,
//     // private organizationsStore: OrganizationsStore,
//     // private organizationsService: OrganizationsService,
//     // private actions$: Actions,
//     private translateService: TranslateService
//   ) {
//     translateService.addLangs(['en', 'pt']);
//     translateService.setDefaultLang('en');
//     const browserLang = translateService.getBrowserLang();
//     // translateService.use(browserLang?.match(/en|pt/) ? browserLang : 'en');
//     translateService.use(
//       usersQuery.getActive()?.language || browserLang || 'en'
//     );
//     this.organization$ = this.organizationsQuery.selectActiveWithRelations();
//     this.organizations$ = organizationsQuery.selectAllWithRelations();
//     this.organizationsService
//       .get(this.usersQuery.getActive()?.defaultOrganizationId + '')
//       .subscribe();

//     this.usersQuery
//       .selectActiveWithRelations()
//       .pipe(untilDestroyed(this))
//       .subscribe((user: User) => {
//         this.user = user as any;
//         this.newNotificationCount =
//           user.notifications?.filter((n) => {
//             if (!n) {
//               return false;
//             }
//             if (n.status !== NotificationStatus.NEW) {
//               return false;
//             }
//             if (n.createdAt && user.lastNotificationPopupOpen) {
//               // console.log(
//               //   n.createdAt,
//               //   user.lastNotificationPopupOpen,
//               //   n.createdAt >= user.lastNotificationPopupOpen
//               // );
//               return n.createdAt >= user.lastNotificationPopupOpen;
//             } else {
//               return true;
//             }
//           }).length || 0;
//         this.ref.markForCheck();
//       });
//   }

//   changeOrganization(organizationId: string) {
//     this.actions$.dispatch(goTo({ url: `/organization/${organizationId}` }));
//     this.usersService
//       .update(this.usersQuery.getActiveId() + '', {
//         defaultOrganizationId: organizationId,
//       })
//       .subscribe();
//     this.organizationsStore.setActive(organizationId);
//   }

//   onClickNavItem(index: number): void {
//     this.sideNavBarItems.map((item: any) => (item.isActive = false));
//     this.sideNavBarItems[index].isActive = true;
//   }

//   ontoggleMenu() {
//     this.isExpanded = !this.isExpanded;
//     this.toggleMenu.emit(this.isExpanded);
//   }

//   mouseEnter(): void {
//     if (!this.isExpanded) {
//       this.toggleMenu.emit(true);
//     }
//   }

//   mouseOut(): void {
//     if (!this.isExpanded) {
//       this.toggleMenu.emit(false);
//     }
//   }

//   logout() {
//     this.auth.logout();
//   }

//   notificationIconClick() {
//     this.isNotificationOpen = !this.isNotificationOpen;
//     this.usersService
//       .update(this.usersQuery.getActiveId() + '', {
//         lastNotificationPopupOpen: new Date().toISOString(),
//       })
//       .subscribe();
//   }
// }

<div class="w-full bg-primary-800">
  <div>

    <div class="flex justify-between align-items w-full p-2">
      <div class="flex items-center cursor-pointer" routerLink="/broadcasts">
        <img src="assets/images/vertice-favicon-192x192.png" alt="Vértice Logo" class="h-8 mr-2" />
        <h2 class="text-white">Vértice</h2>
      </div>

      <div class="flex">
          <button  mat-icon-button [matMenuTriggerFor]="menu" aria-label="Options">
            <mat-icon class="text-white">account_circle</mat-icon>
          </button>
      </div>
    </div>


    <!-- More_vert options -->
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="logout()">
        <mat-icon class="cursor-pointer text-md">logout</mat-icon><span class="text-sm cursor-pointer">{{'common.sign_out' | translate}}</span>
      </button>
    </mat-menu>

  
  </div>



</div>

<!-- <ve-toolbar></ve-toolbar> -->
<!-- <mat-toolbar>
      <mat-menu><button mat-raised-button (click)="accountSettings()"><mat-icon color="text-white">account_settings</mat-icon></button></mat-menu>

</mat-toolbar> -->
<div class="flex flex-col max-h-screen overflow-hidden">
  <!-- <ve-side-navbar [sideNavBarItems]="mainNavBar" class="flex-grow-0"></ve-side-navbar> -->
  <ve-top-navbar  class="flex-grow-0"></ve-top-navbar>
  <!-- <div class="flex-grow block overflow-y-scroll"> -->
  <div class="flex-grow block overflow-y-auto">
    <!-- <ve-breadcrumbs></ve-breadcrumbs> -->

    <router-outlet></router-outlet>
  </div>
</div>
<!-- <ve-file-upload-list></ve-file-upload-list> -->

import { Component } from '@angular/core';

@Component({
  selector: 've-plain-layout',
  templateUrl: './plain-layout.container.html'
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class PlainLayoutContainer {
  title = 'vertice';
}


import { ButtonConfig } from './confirmable.config';
import { ConfirmationComponent } from './confirmation.component';
import { Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

let injector: Injector | undefined;

export function updateInjector(_injector: Injector | undefined) {
  injector = _injector;
}

export function Confirmable(
  message: string,
  title: string,
  buttons: ButtonConfig[] = [
    {
      title: 'No',
      color: 'accent',
      data: false,
    },
    {
      title: 'Yes',
      color: 'warn',
      data: true,
    },
  ]
) {
  return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
    // Again, cache the original method for later use
    const originalMethod = descriptor.value;
    // we write a new implementation for the method
    descriptor.value = async function (...args: any[]) {
      const dialog = injector?.get(MatDialog);
      const dialogRef = dialog?.open(ConfirmationComponent, {
        data: { message, title, buttons },
      });
      const res: { isConfirmed: boolean } = await dialogRef
        ?.afterClosed()
        .toPromise();
      if (res) {
        // run original implementation if user confirms
        const result = originalMethod.apply(this, args);
        return result;
      }
    };
    return descriptor;
  };
}

export async function ConfirmableFn(
  message: string,
  title: string,
  buttons: ButtonConfig[] = [
    {
      title: 'No',
      color: 'accent',
      data: false,
    },
    {
      title: 'Yes',
      color: 'warn',
      data: true,
    },
  ]
) {
  const dialog = injector?.get(MatDialog);
  const dialogRef = dialog?.open(ConfirmationComponent, {
    data: { message, title, buttons },
  });
  const res: { isConfirmed: boolean } = await dialogRef
    ?.afterClosed()
    .toPromise();
  return res;
}

import { AuthLayoutContainer } from './layouts/auth-layout/auth-layout.container';
import {
  authenticatedGuard,
  unauthenticatedGuard,
} from '@vertice/state';

// import {authenticatedGuard,   unauthenticatedGuard} from '../../../../libs/state/src/lib/guards'
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { ScriptLoadGuardService } from './shared/guards/script-load.guard';
// import { SignedInLayoutContainer } from './shared/modules/signed-in-layout/signed-in-layout.container';
import { SignedInLayoutModule } from './shared/modules/signed-in-layout/signed-in-layout.module';
import { SignedInLayoutContainer } from './shared/modules/signed-in-layout/signed-in-layout.container';

export const routes: Routes = [
  { path: '', redirectTo: 'broadcasts', pathMatch: 'full' as const },
  {
    path: '',
    component: AuthLayoutContainer,
    children: [
      {
        path: 'signin',
        data: { title: 'Sign in' },
        canActivate: [unauthenticatedGuard],
        loadChildren: () =>
          import('./modules/login/login.module').then((m) => m.LoginModule),
      },
      {
        path: 'forgot',
        data: { title: 'Forgot password' },
        canActivate: [unauthenticatedGuard],
        loadChildren: () =>
          import('./modules/forgot/forgot.module').then((m) => m.ForgotModule),
      },
      {
        path: 'signup',
        data: { title: 'Sign up' },
        canActivate: [unauthenticatedGuard],
        loadChildren: () =>
          import('./modules/signup/signup.module').then((m) => m.SignupModule),
      },
      {
        path: 'verify',
        data: { title: 'Verify password' },
        loadChildren: () =>
          import('./modules/verify/verify.module').then((m) => m.VerifyModule),
      },
      {
        path: 'reset',
        data: { title: 'Reset password' },
        loadChildren: () =>
          import('./modules/reset/reset.module').then((m) => m.ResetModule),
      },
    ],
  },
{
  path: '',
    component: SignedInLayoutContainer,
    children: [
      {
        path: 'broadcasts',
        data: { title: 'Whatsapp Sender' },
        canActivate: [authenticatedGuard],
        loadChildren: () =>
          import('./modules/whatsapp-sender/whatsapp-sender.module').then(
            (m) => m.WhatsappSenderModule
          ),
      },
    ],
  },
  {
    path: '404',
    data: { title: '404' },
    loadChildren: () =>
      import('./modules/error-not-found/error-not-found.module').then(
        (m) => m.ErrorNotFoundModule
      ),
  },
  {
    path: '408',
    data: { title: '408' },
    loadChildren: () =>
      import('./modules/error-api-timeout/error-api-timeout.module').then(
        (m) => m.ErrorApiTimeoutModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      paramsInheritanceStrategy: 'always',
      // enableTracing: true,
    }),

    SignedInLayoutModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

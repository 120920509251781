import { CommonModule } from '@angular/common';
// import { FileUploadListModule } from '@vertice/ui';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SideNavbarModule } from '../side-navbar/side-navbar.module';
import { SignedInLayoutContainer } from './signed-in-layout.container';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TopNavbarModule } from '../top-navbar/top-navbar.module';
// import { ToolbarModule } from '../toolbar/toolbar.module';

@NgModule({
  declarations: [SignedInLayoutContainer],
  imports: [
    CommonModule,
    RouterModule,
    MatToolbarModule,
    TopNavbarModule,
    // ToolbarModule,
    // FileUploadListModule,
    SideNavbarModule,
  ],
  exports: [SignedInLayoutContainer],
})
export class SignedInLayoutModule {}

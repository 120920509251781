import { CommonModule } from '@angular/common';
import { DialogModule, InitialsModule } from '@vertice/ui';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgModule } from '@angular/core';
// import { NotificationElementsModule } from '../notification-elements/notification-elements.module';
import { OverlayModule } from '@angular/cdk/overlay';
// import { PermissionHelperModule } from '../permission-helper/permission-helper.module';
import { RouterModule } from '@angular/router';
import { SideNavbarComponent } from './side-navbar/side-navbar.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [SideNavbarComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatBadgeModule,
    OverlayModule,
    RouterModule,
    MatTooltipModule,
    DialogModule,
    // NotificationElementsModule,
    MatSelectModule,
    MatFormFieldModule,
    MatButtonModule,
    MatMenuModule,
    InitialsModule,
    // PermissionHelperModule,
    TranslateModule,
  ],
  exports: [SideNavbarComponent],
})
export class SideNavbarModule {}

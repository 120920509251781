<div class="h-screen w-14 bg-primary-800">
  <div>
    <div class="p-3 cursor-pointer" (click)="ontoggleMenu()" (mouseenter)="mouseEnter()" (mouseleave)="mouseOut()"
      routerLink="/broadcasts" >
      <!-- <img src="./assets/placeholder/side-nav.png" /> -->
      <span class="text-white text-2xl">LA</span>
    </div>
    <ng-container *ngFor="let sideNavBarItem of sideNavBarItems; let index = index">
      <div *ngIf="!sideNavBarItem.isBottom">
        <ng-container *ngTemplateOutlet="
            linkEntry;
            context: { index: this.index, item: sideNavBarItem }
          ">
        </ng-container>
      </div>
    </ng-container>
  </div>
  <div class="absolute bottom-4">
    <ng-container *ngFor="let sideNavBarItem of sideNavBarItems; let index = index">
      <div *ngIf="sideNavBarItem.isBottom">
        <ng-container *ngTemplateOutlet="
            linkEntry;
            context: { index: this.index, item: sideNavBarItem }
          ">
        </ng-container>
      </div>
    </ng-container>

    <!-- Notification Icon -->
    <!-- <div class="relative flex items-center justify-center w-10 h-10 p-3 m-1 mb-4 rounded-lg cursor-pointer"
      (click)="notificationIconClick()" [ngClass]="{
        'text-blue-900 bg-white': isNotificationOpen
      }" cdkOverlayOrigin #notificationTrigger="cdkOverlayOrigin">
      <mat-icon mat-list-icon [ngClass]="{ 'text-primary-300': !isNotificationOpen }">
        notifications</mat-icon>
      <div *ngIf="newNotificationCount > 0"
        class="absolute w-4 h-4 text-sm font-bold leading-4 text-center rounded-full text-primary-500 bg-alternative-coral top-1 right-1">
        {{newNotificationCount}}</div>
    </div> -->

    <div class="p-1 cursor-pointer" (click)="isOpen = !isOpen" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
      <mat-icon class="text-white">account_settings</mat-icon>
      <!-- <ve-initials [firstName]="user?.firstName" [lastName]="user?.lastName" [url]="user?.url">
      </ve-initials> -->
    </div>
  </div>

  <ng-template let-item="item" let-index="index" #linkEntry>
    <a [routerLink]="item?.url">
      <div class="p-3 cursor-pointer" [id]="item?.id" (click)="onClickNavItem(index)" [ngClass]="{
          'text-blue-900 bg-white w-10 h-10 flex justify-center items-center m-1 rounded-lg':
            item?.isActive
        }">
        <mat-icon mat-list-icon [ngClass]="{ 'text-primary-300': !item?.isActive }">
          {{ item?.icon }}</mat-icon>
        <!-- <span *ngIf="item?.icon === 'notifications' && hasNewNotifications" class="absolute w-4 h-4 rounded-full bg-alternative-coral top-2 right-2"></span> -->
      </div>
    </a>
  </ng-template>

  <!-- Notification Overlay -->
  <!-- <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="notificationTrigger"
    (overlayOutsideClick)="isNotificationOpen = !isNotificationOpen" [cdkConnectedOverlayOpen]="isNotificationOpen">
    <div (click)="isNotificationOpen = !isNotificationOpen"
      class="absolute rounded-lg shadow-lg side-nav-setting-container w-120 h-80 bottom-12 left-12 shadow-slate-300">
      <ve-notifications-popup></ve-notifications-popup>
    </div>
  </ng-template> -->

  <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" (overlayOutsideClick)="isOpen = !isOpen"
    [cdkConnectedOverlayOpen]="isOpen">
    <div class="absolute rounded-lg shadow-lg side-nav-setting-container w-80 h-96 bottom-12 left-12 shadow-slate-300">
      <div class="flex w-full p-4 pt-4 pb-0 border-b h-2/3" (click)="isOpen = !isOpen">
        <!-- <div class="w-3/6 pt-4 border-r">
          <div bgAccessControl [dataType]="Entity.Organization" [accessType]="AccessType.Update">
            <h5>{{'settings.organization_settings' | translate}}</h5>
            <ul>
              <li>
                <a [routerLink]="[
                  '/organization',
                  (organization$ | async)?.id,
                  'settings',
                  'main'
                ]">
                  <mat-icon>settings</mat-icon>
                  <span class="pl-2">{{'settings.title' | translate}}</span>
                </a>
              </li>
              <li>
                <a [routerLink]="[
                  '/organization',
                  (organization$ | async)?.id,
                  'settings',
                  'users'
                ]">
                  <mat-icon>supervisor_account</mat-icon>
                  <span class="pl-2">{{'common.invite_users' | translate}}</span>
                </a>
              </li>
            </ul>
          </div>
        </div> -->
        <div class="relative w-3/6 pt-4 pl-3">
          <h5>{{'settings.account_settings' | translate}}</h5>
          <ul>
            <li>
              <a [routerLink]="['/me']">
                <mat-icon>how_to_reg</mat-icon>
                <span>{{'settings.my_profile' | translate}}</span>
              </a>
            </li>
            <!-- <li>
              <mat-icon>supervised_user_circle</mat-icon>
              <span>Account settings</span>
            </li> -->
          </ul>
          <div class="absolute flex items-center text-blue-600 cursor-pointer bottom-4" (click)="logout()">
            <mat-icon class="cursor-pointer text-md">logout</mat-icon>
            <span class="text-xs cursor-pointer">{{'common.sign_out' | translate}}</span>
          </div>
        </div>
      </div>
      <!-- <div class="p-4">
        <ve-organization-selector [activeOrganizationId$]="activeOrganizationId$"
          [organizations]="organizations$ | async" (selectionChange)="changeOrganization($event)">>
        </ve-organization-selector>
      </div> -->
    </div>
  </ng-template>
</div>
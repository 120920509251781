/* eslint-disable @angular-eslint/component-class-suffix */
import { AuthService } from '@vertice/state';
import { Component } from '@angular/core';
import { DialogService } from '@vertice/ui';
// import { EventSettingsInviteUsersComponent } from '../../../modules/event-settings/containers';
// import { NotificationStatus } from '@vertice/enums';
import { first } from 'rxjs';

@Component({
  selector: 've-signed-in-layout',
  templateUrl: './signed-in-layout.container.html',
})
export class SignedInLayoutContainer {
  // organizationId: string | undefined;
  mainNavBar: unknown;
  hasNewNotifications = false;
  constructor(
    // private organizationsQuery: OrganizationsQuery,
    protected readonly dialog: DialogService,
    protected readonly authService: AuthService,
    // private usersQuery: UsersQuery
  ) {
    this.setItems();
  }

  setItems() {
    this.mainNavBar = [
      // { label: 'home', icon: 'home', isActive: false, url: '/' },
      // { label: 'calendar', icon: 'chrome_reader_mode', isActive: false },
      { label: 'blueprint', icon: 'dashboard', isActive: false, url: '/' },
      { label: 'Upgrade', icon: 'upgrade', isActive: false, url: '/' },
      //   label: 'notifications',
      //   icon: 'notifications',
      //   isActive: false,
      //   isBottom: true,
      //   url: '/notifications',
      // },
      // {
      //   label: 'help',
      //   icon: 'help',
      //   isActive: false,
      //   id: 'intercom',
      //   isBottom: true,
      // },
    ];

  }

  onLogout(): void {
    this.authService.logout();
  }
}
// @Component({
//   selector: 've-signed-in-layout',
//   templateUrl: './signed-in-layout.container.html',
// })
// export class SignedInLayoutContainer {
//   organizationId: string | undefined;
//   mainNavBar: any;
//   hasNewNotifications = false;
//   constructor(
//     // private organizationsQuery: OrganizationsQuery,
//     protected readonly dialog: DialogService,
//     protected readonly authService: AuthService,
//     private usersQuery: UsersQuery
//   ) {
//     this.setItems();
//     // this.organizationsQuery.selectActiveId().subscribe((id) => {
//     //   this.organizationId = id + '';
//     //   this.setItems();
//     // });
//     this.usersQuery.selectActiveWithRelations().subscribe((user) => {
//       // console.log('user in signedin layout', user);
//       // this.hasNewNotifications =
//       //   user?.notifications?.find(
//       //     (n) => n?.status === NotificationStatus.NEW
//       //   ) != null;
//     });
//   }

//   setItems() {
//     this.mainNavBar = [
//       // { label: 'home', icon: 'home', isActive: false, url: '/' },
//       // { label: 'calendar', icon: 'chrome_reader_mode', isActive: false },
//       { label: 'event', icon: 'stadium', isActive: false, url: '/' },
//       //   label: 'notifications',
//       //   icon: 'notifications',
//       //   isActive: false,
//       //   isBottom: true,
//       //   url: '/notifications',
//       // },
//       {
//         label: 'help',
//         icon: 'help',
//         isActive: false,
//         id: 'intercom',
//         isBottom: true,
//       },
//     ];

//     // if (this.usersQuery.getActive()?.bgAdmin === true) {
//     // this.mainNavBar.push({
//     //   label: 'templates',
//     //   icon: 'description',
//     //   isActive: false,
//     //   url: `/organization/${this.organizationId}/templates`,
//     // });
//     // }
//   }

//   // inviteUser(): void {
//   //   const obs$ = this.dialog
//   //     .open(EventSettingsInviteUsersComponent, {
//   //       width: '1008px',
//   //       backdropClass: 'p-0',
//   //       hasBackdrop: true,
//   //       panelClass: 'p-0',
//   //       data: {
//   //         title: 'Invite guests',
//   //       },
//   //     })
//   //     .afterClosed();

//   //   obs$.pipe(first()).subscribe();
//   // }

//   onLogout(): void {
//     this.authService.logout();
//   }
// }

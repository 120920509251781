import { Component, Inject, OnInit } from '@angular/core';
import { ConfirmableConfig } from './confirmable.config';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 've-confirmation',
  templateUrl: './confirmation.component.html',
  styles: [],
})
export class ConfirmationComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmableConfig) {}

  ngOnInit(): void {
    return;
  }
}

import { ChangeDetectorRef } from '@angular/core';
import { Component } from '@angular/core';
import { LoadingBarService } from '../../../services/loading-bar.service';

@Component({
  selector: 've-loading-bar',
  templateUrl: './loading-bar.container.html',
  styleUrls: ['./loading-bar.container.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class LoadingBarContainer {
  loading = false;

  constructor(
    private loadingBarService: LoadingBarService,
    private cdRef: ChangeDetectorRef
  ) {
    this.loadingBarService.inProgress$.subscribe((val) => {
      this.loading = val;
      this.cdRef?.detectChanges();
    });
  }
}

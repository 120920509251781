<div class="flex w-full h-full">
  <div class="flex w-full md:w-7/12 mt-8 px-4 justify-center">
    <div class="w-full">
      <!-- <img src="./assets/placeholder/login-header.svg" /> -->
      <div class="flex justify-center mt-6">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <div class="md:w-5/12 bg-yellow-200 hidden justify-center items-center md:flex">
    <!-- <img src="./assets/bg-signup/nobg.svg" /> -->
  </div>
</div>

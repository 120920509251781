import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular';



// ? Sentry error logging
// Sentry.init({
//   dsn: 'https://5dc2ac631cf4577a021e43467ce5802b@o4507396788256768.ingest.us.sentry.io/4507396790616064',
//   integrations: [
//     // Registers and configures the Tracing integration,
//     // which automatically instruments your application to monitor its
//     // performance, including custom Angular routing instrumentation
//     Sentry.browserTracingIntegration(),
//     // Registers the Replay integration,
//     // which automatically captures Session Replays
//     Sentry.replayIntegration(),
//   ],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for tracing.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,

//   // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
//   tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],

//   // Capture Replay for 10% of all sessions,
//   // plus for 100% of sessions with an error
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

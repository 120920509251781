import { BrowserModule } from '@angular/platform-browser';

// import { AppController } from './app.controller';
// import { AppService } from './app.service';

import { AppRoutingModule } from './app-routing.module';
import { APP_INITIALIZER, ErrorHandler, NgModule, importProvidersFrom } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { layouts } from './layouts';
import { TimeagoModule } from 'ngx-timeago';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';

import { NgxsModule } from '@ngxs/store';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
// import { NovelsState } from './novels.state';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

import {
  UserState,
  AuthState,
  BaererInterceptor,
  // RouterActionState,
  guards,
  AuthInterceptor,
} from '@vertice/state';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { ConfirmationModule } from './shared/modules/confirmation/confirmation.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LoadingBarModule } from './shared/modules/loading-bar/loading-bar.module';
import { ToastrModule } from 'ngx-toastr';

import {
    HTTP_INTERCEPTORS,
    HttpClient,
    HttpClientModule,
} from '@angular/common/http';
import { AppComponent } from './app.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { WhatsappSenderState } from '../../../../libs/state/src/lib/whatsapp-sender/whatsapp-sender.state';

export function HttpLoaderFactory(http: HttpClient) {
  return  new  TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent, layouts],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatDialogModule,
    LoadingBarModule,
    LoadingBarModule,
    ConfirmationModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    // TranslateModule.forRoot(),

    // NxWelcomeComponent,
    // NgxMatNativeDateModule, // Add this line
    TimeagoModule.forRoot(),
    NgxsLoggerPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsModule.forRoot([
      AuthState,
      UserState,
      WhatsappSenderState,
    ]),

    NgxsStoragePluginModule.forRoot(
    ),
    ToastrModule.forRoot(),
  ],
  // controllers: [AppController],
  providers: [
    importProvidersFrom(
      TranslateModule.forRoot({
        defaultLanguage: 'pt',
        loader: {
          provide: TranslateLoader,
          deps: [HttpClient],
          useFactory: HttpLoaderFactory,
        },
      })
    ),
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline', floatLabel: 'always' },
    },
    guards,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaererInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    // sentry
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  constructor(trace: Sentry.TraceService) {}
}

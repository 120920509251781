import { CommonModule } from '@angular/common';
import { LoadingBarContainer } from './loading-bar/loading-bar.container';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [LoadingBarContainer],
  imports: [CommonModule],
  exports: [LoadingBarContainer],
})
export class LoadingBarModule {}
